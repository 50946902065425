import { captureException } from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';

import { nnErrorSchema } from '@/hooks/fetch/use-auth-fetch-ky';
import { DEFAULT_ERROR_MESSAGE } from './constants';

export const FileCircleExclamationIcon = dynamic(
  () =>
    import('@/components/icons/file-circle-exclamation-icon').then(
      (mod) => mod.FileCircleExclamationIcon,
    ),
  { ssr: false },
);
export const LinkSlashIcon = dynamic(
  () =>
    import('@/components/icons/link-slash-icon').then(
      (mod) => mod.LinkSlashIcon,
    ),
  { ssr: false },
);

interface ErrorMeta {
  message: string;
  type: 'ERROR' | 'NONE';
}

interface Props {
  error: unknown;
}

export function RootLayoutError(props: Props) {
  const { error } = props;

  const t = useTranslations('rootLayoutError');

  const maybeNnErrors = nnErrorSchema.safeParse(error);
  const nnErrors = maybeNnErrors.success ? maybeNnErrors.data?.errors : null;

  /**
   * NOTE (rongsen): Log errors to Sentry only if they are neither 404 errors nor NN errors with code NN1002.
   */
  const errorMeta: ErrorMeta = {
    message: DEFAULT_ERROR_MESSAGE,
    type: 'ERROR',
  };
  if (nnErrors && nnErrors.length > 0) {
    const [firstNnError] = nnErrors;

    errorMeta.message =
      nnErrors.length > 1
        ? `NN errors: ${JSON.stringify(nnErrors)}`
        : firstNnError.message;
    errorMeta.type = firstNnError.code === 'NN1002' ? 'NONE' : 'ERROR';
  } else if (error instanceof Response) {
    errorMeta.message = `${error.status}: ${error.statusText}`;
    errorMeta.type = error.status === 404 ? 'NONE' : 'ERROR';
  } else {
    errorMeta.message = error?.toString() || DEFAULT_ERROR_MESSAGE;
  }

  if (errorMeta.type === 'ERROR') {
    captureException(new Error(errorMeta.message, { cause: error }));
  }

  const firstNnError = nnErrors?.at(0);
  if (firstNnError?.code === 'NN1002') {
    const pageNotFoundText = t('pageNotFound');
    const cannotBeFoundText = t('cannotBeFound');
    const contactSupportText = t('contactSupport');

    return (
      <div className="flex h-full w-full flex-col items-start justify-center p-4 lg:items-center">
        <div>
          <div className="mb-6 flex flex-col items-start gap-5 lg:flex-row lg:items-end">
            <FileCircleExclamationIcon className="mr-auto inline-block h-8 text-error lg:mr-0" />
            <h1 className="font-bold text-black text-heading-2">
              {pageNotFoundText}
            </h1>
          </div>
          <p className="mb-8 max-w-[600px]">{cannotBeFoundText}</p>

          <p className="max-w-[600px]">{contactSupportText}</p>
        </div>
      </div>
    );
  }

  const failToLoadText = t('failToLoad');
  const noLongerExistText = t('noLongerExist');

  return (
    <div className="mx-auto flex h-full w-full flex-col justify-center px-6 lg:max-w-[600px] lg:px-0">
      <div className="mb-4 flex flex-col lg:mb-6 lg:flex-row">
        <LinkSlashIcon className="mb-6 mr-auto inline-block h-8 text-error lg:mb-0 lg:mr-2" />
        <h1 className="font-bold text-black text-heading-2">
          {failToLoadText}
        </h1>
      </div>
      <p className="text-black">{noLongerExistText}</p>
    </div>
  );
}
