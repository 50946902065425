'use client';

import { NextIntlClientProvider } from 'next-intl';
import type Error from 'next/error';

import { RootLayoutError } from '@/components/root-layout-error/root-layout-error';
import { RootLayoutTemplate } from '@/components/root-layout-template/root-layout-template';
import { defaultLocale } from '@/i18n/constants';
import rootLayoutError from '@/i18n/messages/en-US/(components)/root-layout-error';

/**
 * NOTE (rongsen): This is a global error page displayed when an error occurs in the app.
 *
 * However, this error page is unlikely to be shown in all environments because the root layout component
 * already has a try...catch block to handle server errors. A common scenario for triggering this global error page
 * is when `fetchBootstrapApi` fails.
 *
 * The downside of this global error page is that it can only be a client component. Shipping messages for all languages
 * to the client is not ideal, and Tailwind CSS is not being injected into the document. This results in the error page
 * looking very plain and sometimes broken due to missing styles.
 *
 * The workaround is to handle errors in the root layout component for server errors, which avoids these issues.
 * This global error page remains useful for unexpected errors not caught by the root layout component. Since this is
 * a last resort for error handling at the root level, it is not expected to be shown often. Therefore, it will be rendered
 * in en-US only.
 */
export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <RootLayoutTemplate
      locale={defaultLocale}
      bodyProps={{
        className: 'text-body h-screen w-screen',
      }}
    >
      <NextIntlClientProvider locale={defaultLocale} messages={rootLayoutError}>
        <RootLayoutError error={error} />
      </NextIntlClientProvider>
    </RootLayoutTemplate>
  );
}
