const _default = {
  rootLayoutError: {
    cannotBeFound: `We're sorry, but the page you're looking for cannot be found.`,
    contactSupport:
      'If you continue to experience issues, please contact our support team for assistance.',
    failToLoad: 'Failed to load.',
    noLongerExist:
      'Sorry, the page you are looking for does not exist anymore or there was an error in the link you followed or typed.',
    pageNotFound: 'Page not found',
  },
};

export default _default;
